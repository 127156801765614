@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '@appkit4/styles/appkit.min.css'; 
@import '@appkit4/react-components/dist/styles/appkit4-react.min.css';
@import '@appkit4/react-text-editor/dist/appkit4-react-texteditor.min.css';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
